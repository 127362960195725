<template>
    <Icon v-if="success" :size="size" :name="successIcon" />
    <Icon v-else-if="error" :size="size" name="warning" />
    <svg
        v-else-if="spinnerClass === 'magic'"
        :size="size"
        name="loading-magic"
        class="spin magic"
        viewBox="0 0 24 24"
        :width="`${size}px`"
        :height="`${size}px`"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00024 12C2.00024 6.48 6.48024 2 12.0002 2C17.5202 2 22.0002 6.48 22.0002 12C22.0002 17.52 17.5202 22 12.0002 22C6.48024 22 2.00024 17.52 2.00024 12ZM5 11.9999C5 15.8674 8.1325 18.9999 12 18.9999C15.8675 18.9999 19 15.8674 19 11.9999C19 8.13238 15.8675 4.99988 12 4.99988C8.1325 4.99988 5 8.13238 5 11.9999Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1685 19.0418C20.9619 17.2341 22.0704 14.7457 22.0704 12C22.0704 6.48 17.5904 2 12.0704 2C9.32467 2 6.83629 3.10842 5.02856 4.90191L7.1481 7.02145C8.41237 5.77142 10.1507 4.99988 12.0701 4.99988C15.9376 4.99988 19.0701 8.13238 19.0701 11.9999C19.0701 13.9193 18.2986 15.6576 17.0486 16.9219L19.1685 19.0418Z" :fill="`url(#${_.uid})`"/>
        <defs>
            <linearGradient :id="_.uid" x1="8.76861" y1="0.608481" x2="24.5381" y2="3.46617" gradientUnits="userSpaceOnUse">
                <stop stop-color="#6545FC" />
                <stop offset="1" stop-color="#9044FC" />
            </linearGradient>
        </defs>
    </svg>
    <div
        v-else
        :style="{
            'width' : `${size}px`,
            'height' : `${size}px`,
            'border-width' : `${border}px`
        }"
        :class="[ inline, spinnerClass ]"
        class="spinner spin"
    />
</template>

<script>
import Icon from '@/components/utility/icon';

export default {
    name: 'Spinner',
    components: { Icon },
    props: {
        size: { type: String, default: '24' },
        spinnerClass: { type: String, default: null, options: 'colourClass' },
        successIcon: { type: String, default: 'correct', options: 'icon' },

        inline: { type: Boolean, default: false },
        success: { type: Boolean, default: false },
        error: { type: Boolean, default: false },
    },
    computed: {
        border() {
            return Number(this.size) / 6;
        }
    }
};
</script>

<style lang="less" scoped>

.spinner {
    flex: 0 0 auto;
    display: block;
    border-radius: 100%;
    background: 0;
    border-style: solid;
    transform: translateZ(0);
    border-color: ~"rgba(var(--coal-rgb), 0.2)";
    border-left-color: var(--primary);

    &.inline {
        position: absolute;
        right: 3px;
        top: 3px;
    }
    &.primary {
        border-color: var(--primary-tint);
        border-left-color: var(--primary);
    }
    &.white {
        border-color: #FFF;
        border-left-color: var(--primary);
    }
    &.navy {
        border-color: #FFF;
        border-left-color: var(--primary-shade-2);
    }
}
.spin {
    animation: spin 1s infinite linear;
}
</style>
<style lang="less">
@keyframes spin {
  0%   { transform: rotate(0deg)   }
  100% { transform: rotate(360deg) }
}
</style>
